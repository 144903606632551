import React, { FC, useCallback } from 'react';
import { BeveledContainer } from '../../../components/BeveledContainer';
import { DepositType } from '../../../dto';
import { depositCalculationsStore } from '../../../store/depositCalculationsStore';
import { observer } from 'mobx-react';
import { Select } from '../../../components/Select';
import { END_TERM_DEPOSIT_TYPE_ID, PER_MONTH_DEPOSIT_TYPE_ID } from '../../../store/constants';

type Props = {
    disabled?: boolean;
};

export const SelectDepositType: FC<Props> = observer(({ disabled = false }) => {
    const onChange = useCallback((value: DepositType) => {
        depositCalculationsStore.setDepositType(value);
    }, []);

    const renderValue: (v: DepositType, inList: boolean) => React.ReactNode = useCallback(item => {
        if (item.id === END_TERM_DEPOSIT_TYPE_ID) {
            return (
                <>
                    В конце срока
                </>
            );
        }

        if (item.id === PER_MONTH_DEPOSIT_TYPE_ID) {
            return (
                <>
                    Ежемесячно
                </>
            );
        }
        return item.name;
    }, []);

    return (
        <BeveledContainer>
            <Select<DepositType>
                label="Выплата дохода"
                name={'depositType'}
                items={depositCalculationsStore.depositTypes}
                renderValue={renderValue}
                keyGetter={item => item.id}
                error={!!depositCalculationsStore.error}
                helperText={depositCalculationsStore.error}
                variant="standard"
                value={depositCalculationsStore.depositType}
                onChange={onChange}
                readOnly={disabled}
                required
            />
        </BeveledContainer>
    );
});
