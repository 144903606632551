import { Box, styled } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { Text } from '../../../components/Text';
import { END_TERM_DEPOSIT_TYPE_ID, PER_MONTH_DEPOSIT_TYPE_ID } from '../../../store/constants';
import { depositCalculationsStore } from '../../../store/depositCalculationsStore';
import { formatCurrency, formatPercent } from '../../../utils/formatters';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
});

export const ConfirmCreateDepositOptions = observer(() => {
    const depositType = useMemo(() => {
        if (depositCalculationsStore.depositType?.id === END_TERM_DEPOSIT_TYPE_ID) {
            return 'в конце срока';
        }

        if (depositCalculationsStore.depositType?.id === PER_MONTH_DEPOSIT_TYPE_ID) {
            return 'ежемесячно';
        }

        return undefined;
    }, []);

    return (
        <Box>
            <Text sx={{ marginBottom: '32px' }}>Нажимая на кнопку "подтвердить" я подтверждаю что:</Text>
            <Container>
                <Text fontWeight={600}>
                    — осознаю, что инвестирование моих денежных средств сопряжено с рисками и может привести к
                    уменьшению либо потере моих инвестиций в случае получения убытков компанией НурФинанс
                </Text>
                <Text>— понимаю, что при выводе мне дохода с Инвесткопилки, автоматически удерживается налог НДФЛ</Text>
                <Text>
                    — сумма моего инвестиционного взноса в рамках данной инвесткопилки составляет{' '}
                    {formatCurrency(depositCalculationsStore.sum)}
                </Text>
                <Text>— срок инвестиции составляет {depositCalculationsStore.duration} мес.</Text>
                <Text>
                    — сумма ежемесячного пополнения составляет {formatCurrency(depositCalculationsStore.replenishment)}
                </Text>
                <Text>
                    — доля в прибыли в рамках данного инвестиционного продукта составляет{' '}
                    {formatPercent(depositCalculationsStore.interval?.sharePercent)}
                </Text>
                {depositType && <Text>— выплата дохода от инвестирования осуществляется - {depositType}</Text>}
            </Container>
        </Box>
    );
});
