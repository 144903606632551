import React from 'react';
import { useHistory } from 'react-router';
import { HelpOutline } from '@mui/icons-material';
import { ToolbarButton } from './ToolbarButton';

export const HelpButton = () => {
    const history = useHistory();

    return <ToolbarButton onClick={() => history.push('/help')} Icon={HelpOutline} label="Помощь" color="secondary" />;
};
