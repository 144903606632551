import React from 'react'
import { Layout } from "../../components/Layout"

export const HelpPage = () => {
    return (
        <Layout title={'помощь'}>
            <div style={{ height: 'calc(100vh - 180px)'}}>
                <iframe title="help" src="/help.html" style={{ border: 'none'}} height="100%" width="100%" ></iframe>
            </div> 
        </Layout>
    )
}