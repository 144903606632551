import { Route, Switch } from 'react-router-dom';
import { DepositPage } from './HomePage/DepositPage';
import { HomePage } from './HomePage/HomePage';
import { FinishRegistrationPage } from './FinishRegistrationPage';
import { observer } from 'mobx-react';
import { authStore } from '../store/authStore';
import { UserStatus } from '../types';
import { ProfilePage } from './ProfilePage';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { ChangePasswordPage } from './ChangePasswordPage';
import { reloadUser } from '../utils/users';
import { CreateDepositPage } from './CreateDepositPage';
import { HelpPage } from './HelpPage';

export const RootNavigation = observer(() => {
    const { user } = authStore;

    const history = useHistory();

    useEffect(() => {
        if (!user?.email) {
            reloadUser().catch(() => {
                authStore.logout();
                history.push('/');
            });
        }
    }, [user?.email, history]);

    if (!user) return <CircularProgress />;

    if (user.forcePasswordChange) return <ChangePasswordPage />;

    return (
        <Switch>
            <Route path="/deposits/:id" component={DepositPage} />
            <Route
                path="/profile"
                component={user.status === UserStatus.DRAFT ? FinishRegistrationPage : ProfilePage}
            />
            <Route path="/create" component={CreateDepositPage} />
            <Route path="/help" component={HelpPage} />
            <Route path="/" component={HomePage} />
        </Switch>
    );
});
