import React, { FC, useMemo, useState } from 'react';
import { Button, Grid, styled } from '@mui/material';
import { Text } from '../../../components/Text';
import { Slider } from '../../../components/Slider';
import { formatCurrency } from '../../../utils/formatters';
import { canAcceptPayment, getInterval, getMonthlyIncome, isDepositActive } from '../../../utils/deposits';
import { DepositDetailedInfo } from '../../../dto';
import {
    DEFAULT_REPLENISHMENT,
    MAX_REPLENISHMENT,
    MIN_REPLENISHMENT,
    STEP_REPLENISHMENT,
} from '../../../store/constants';

type Props = {
    deposit: DepositDetailedInfo;
    onPaymentSelect: (sum: number) => void;
};

const Root = styled('div')({
    marginTop: '33px',
});

const ActionContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});

const Label = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
});

type LabelWithInfoProps = {
    label: string;
    info: string;
};

const LargeButton = styled(Button)({
    width: '100%',
    textDecoration: 'none',
});

const LabelWithInfo: FC<LabelWithInfoProps> = ({ label, info }) => {
    return (
        <Label>
            <Text variant="HeaderBold" color="primary">
                {label}
            </Text>
            <Text variant="SmallBold" color="secondary">
                {info}
            </Text>
        </Label>
    );
};

type InfoProps = {
    deposit: DepositDetailedInfo;
    replenishment: number;
};

const ActionInfo: FC<InfoProps> = ({ deposit, replenishment }) => {
    const additionalMonthIncome = useMemo(() => {
        const interval = getInterval(deposit.conditions, deposit.sumTotal, deposit.duration);
        if (!interval) return null;

        return getMonthlyIncome(interval, deposit.sumTotal + replenishment);
    }, [deposit, replenishment]);

    return (
        <ActionContainer>
            <LabelWithInfo label={formatCurrency(additionalMonthIncome)} info="Ежемесячная прибыль" />
            {replenishment === MAX_REPLENISHMENT ? (
                <Grid item xs={12}>
                    <Text variant="Body" color="primary">{`Для увеличения вклада на сумму свыше ${formatCurrency(
                        MAX_REPLENISHMENT
                    )} обратитесь к
                сотруднику компании`}</Text>
                </Grid>
            ) : null}
        </ActionContainer>
    );
};

export const DepositActions: FC<Props> = ({ deposit, onPaymentSelect }) => {
    const [value, setValue] = useState(DEFAULT_REPLENISHMENT);

    const isActive = isDepositActive(deposit);
    const acceptsPayment = canAcceptPayment(deposit);

    if (!isActive && !acceptsPayment) return null;

    return (
        <Root>
            {isActive && (
                <Slider
                    value={value}
                    setValue={setValue}
                    label={'Увеличить сумму вклада'}
                    actionName={'Пополнить'}
                    action={() => onPaymentSelect(value)}
                    defaultValue={value}
                    min={MIN_REPLENISHMENT}
                    max={MAX_REPLENISHMENT}
                    step={STEP_REPLENISHMENT}
                    formatValue={value => `+ ${formatCurrency(value)}`}
                    additionalInfo={<ActionInfo deposit={deposit} replenishment={value} />}
                />
            )}
            {acceptsPayment && (
                <LargeButton variant="contained" onClick={() => onPaymentSelect(deposit.firstPayment)}>
                    Оплатить
                </LargeButton>
            )}
        </Root>
    );
};
