import { Container, Paper, AppBar, Toolbar, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import { LogoutButton } from './LogoutButton';
import { Logo } from './Logo';
import { ProfileButton } from './ProfileButton';
import { authStore } from '../../store/authStore';
import { observer } from 'mobx-react';
import { HomeButton } from './HomeButton';
import { mockApi } from '../../api/constants';
import { Text } from '../../components/Text';
import { HelpButton } from './HelpButton';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    minHeight: 'calc(100% - 66px)',
    backgroundColor: theme.palette.background.default,
    paddingTop: 66,
}));

const Bar = styled(AppBar)(({ theme }) => ({
    minHeight: 66,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
}));

const HeaderToolbar = styled(Toolbar)({
    minHeight: 66,
});

const Wrapper = styled(Container)({
    padding: 0,
});

const Content = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    paddingBottom: 90,
});

const Spacer = styled('div')({
    flex: 1,
    textAlign: 'center',
});

type Props = PropsWithChildren<{ title?: string }>;

export const Layout = observer(({ children }: Props) => {
    return (
        <Root>
            <Bar>
                <HeaderToolbar>
                    {/** Заглушка под кнопку меню */}
                    <div style={{ width: 40 }} />
                    <Logo />
                    <Spacer>{mockApi ? <Text color="error">Используется заглушка API</Text> : null}</Spacer>
                </HeaderToolbar>
            </Bar>
            <Wrapper maxWidth="sm">
                <Content>{children}</Content>
            </Wrapper>
            {authStore.canViewProfile && (
                <Bar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
                    <HeaderToolbar>
                        <HomeButton />
                        <Spacer />
                        <HelpButton />
                        <Spacer />
                        <ProfileButton />
                        <Spacer />
                        <LogoutButton />
                    </HeaderToolbar>
                </Bar>
            )}
        </Root>
    );
});
