import { PaymentData } from '../../../../types';
import { Button, LinearProgress, styled, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { DepositHeader } from '../DepositHeader';
import { BackButton } from '../../../../components/Layout';
import { PaymentQR } from './PaymentQR';
import { PaymentManual } from './PaymentManual';
import { useRequest } from '../../../../hooks/useRequest';
import { paymentAttributesRequest } from './paymentAttributesRequest';
import { authStore } from '../../../../store/authStore';
import { getPaymentPurpose, isOldDepositType } from '../../../../utils/deposits';
import { DepositDetailedInfo } from '../../../../dto';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { PER_MONTH_DEPOSIT_TYPE_NAME, END_TERM_DEPOSIT_TYPE_NAME } from '../../../../store/constants';

type Props = {
    deposit: DepositDetailedInfo;
    sum: number;
    onBack: () => void;
};

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

const Buttons = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
});

const SelectModeButton = styled(Button)({
    width: '100%',
});

type Mode = 'QR' | 'Manual';

export const PaymentScreen: FC<Props> = ({ deposit, sum, onBack }) => {
    const depositName = useMemo(() => {
        if (isOldDepositType(deposit)) {
            return deposit.name
        }

        return 'Инвесткопилка'
    }, [deposit]);

    const { data, error, loading } = useRequest(paymentAttributesRequest, undefined);

    const [mode, setMode] = React.useState<Mode | null>(null);

    const onBackClick = () => {
        if (!mode) {
            onBack();
        } else {
            setMode(null);
        }
    };

    const paymentData: PaymentData | null =
        data && authStore.user
            ? {
                  user: authStore.user,
                  sum,
                  purpose: getPaymentPurpose(deposit),
                  ...data,
              }
            : null;

    return (
        <Root>
            {loading && <LinearProgress />}
            {error && <Typography color="error">{error.message}</Typography>}
            {data && paymentData && (
                <>
                    <Breadcrumbs
                        paths={[
                            { title: 'Главная', link: '/' },
                            { title: deposit.alias || depositName, link: `/deposits/${deposit.id}` },
                            { title: 'Пополнение' },
                        ]}
                    />
                    <DepositHeader deposit={deposit} />
                    {!mode && (
                        <Buttons>
                            <SelectModeButton onClick={() => setMode('QR')} variant="outlined">
                                Оплата по QR-коду
                            </SelectModeButton>
                            <SelectModeButton onClick={() => setMode('Manual')} variant="outlined">
                                Оплата по реквизитам
                            </SelectModeButton>
                        </Buttons>
                    )}
                    {mode === 'QR' && <PaymentQR paymentData={paymentData} />}
                    {mode === 'Manual' && <PaymentManual paymentData={paymentData} />}
                    <Button onClick={onBackClick} color="primary" variant="contained">
                        Назад
                    </Button>
                </>
            )}
        </Root>
    );
};
