import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material';
import { formatCurrency } from '../../utils/formatters';
import { Text } from '../../components/Text';
import { Colors } from '../../theme/colors';
import { DepositInfo } from '../../dto';
import { BeveledContainer } from '../../components/BeveledContainer';
import { Link as RouterLink } from 'react-router-dom';
import { KeyboardArrowRight } from '@mui/icons-material';
import { END_TERM_DEPOSIT_TYPE_NAME, PER_MONTH_DEPOSIT_TYPE_NAME } from '../../store/constants';
import { isOldDepositType } from '../../utils/deposits';

type Props = {
    deposit: DepositInfo;
    onClick: () => void;
};

const Container = styled(BeveledContainer)({
    padding: 10,
    cursor: 'pointer',
});

const Content = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
});

const Image = styled('img')({
    maxWidth: '24px',
    maxHeight: '24px',
});

const Info = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '3px',
    alignItems: 'center',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const Circle = styled('div')({
    maxWidth: '32px',
    maxHeight: '32px',
    minWidth: '32px',
    minHeight: '32px',
    backgroundColor: Colors.lightestBlue,
    borderRadius: '16px 16px 16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Link = styled(RouterLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'none',
}));

const KeyboardArrowRightIcon = styled(KeyboardArrowRight)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

export const DepositItem: FC<Props> = ({ deposit, onClick }) => {
    const depositName = useMemo(() => {
        if (isOldDepositType(deposit)) {
            return deposit.name
        }

        return 'Инвесткопилка'
    }, [deposit]);
    return (
        <Container onClick={onClick}>
            <Content>
                <Circle>
                    <Image src={`data:image/jpeg;base64,${deposit.typeIcon}`} />
                </Circle>
                <Info>
                    <Row style={{ flex: 1 }}>
                        <Text fontWeight="500">{deposit.alias || 'Без названия'}</Text>
                        <Text variant="Small" color="secondary">
                            {depositName}
                        </Text>
                        <Text variant="Small">{formatCurrency(deposit.sumTotal)}</Text>
                    </Row>
                    <Row>
                        <KeyboardArrowRightIcon />
                    </Row>
                    <Row>
                        <Text color="primary">Перейти</Text>
                    </Row>
                </Info>
            </Content>
        </Container>
    );
};
